export default {
    // baseUrl: 'http://192.168.1.3:9004/api/manager',
    baseUrl: 'https://box.90hou.xyz/api/manager',
    doLogin:'/storeUser/user/login',//登录
    getUserList:'/storeUser/user/list/box',//获取用户列表
    addMallCategory:'/storeCategory/category/list',//获取所有分类
    updateCategory:'/storeCategory/category/add',//添加/修改分类
    deleteCategory:'/storeCategory/category/delete',//删除分类
    getBoxList:'/storeBlindBox/list/box',//获取盲盒列表
    updateBoxPrice:'/storeBlindBox/update/box',//新增/修改盲盒
    getShopList:'/storeGoods/list/goods',//获取商品列表
    addShopInfo:'/storeGoods/add/good',//新增商品
    addPhoto:'/addPhoto',//上传图片
    changeShopInfo:'/changeShopInfo',//修改商品
    getCardTypeList:'/getCardTypeList',//获取虚拟物品列表
    getShopByCategory:'/getShopByCategory',//获取分类下的商品列表
    addCategoryShop:'/addCategoryShop',//添加分类商品
    deleteCateGoryShop:'/deleteCateGoryShop',//移除分类商品
    getCategoryWithoutShopList:'/getCategoryWithoutShopList',//获取可以添加到该分类的商品
    deleteBox:'/storeBlindBox/box/delete',//删除盲盒信息
    getBoxDetail:'/storeBoxGoods/goods/list',//获取盲盒对应的商品
    getOrderListByStatus:'/getOrderListByStatus',//获取订单列表
    importCardListFromExcel:'/importCardListFromExcel',//导入卡密
    exportCard:'/exportCard',//导出卡号卡密
    changeOrderToCancle:'/changeOrderToCancle',//取消订单
    changeOrderToError:'/changeOrderToError',//修改订单状态为异常订单
    changeOrderToSended:'/storeOrder/changeOrderToSended',//发货
    getCardListByOrderid:'/getCardListByOrderid',//获取虚拟发货的卡号
    changeOrderToSendedByCard:'/changeOrderToSendedByCard',//虚拟物品发货
    getCancelOperate:'/getCancelOperate',//取消订单相关数据
    getOperate:'/getOperate',//获取运营数据
    getManageHistory:'/getManageHistory',//查看操作日志
    getManageList:'/storeUser/user/list/power',//获取管理员列表
    getBoxCanAddShopList:'/storeCategory/category/list/goods',//获取可以添加到盲盒的商品
    addBoxShop:'/storeBoxGoods/goods/add',//添加/修改盲盒商品
    deleteBoxShop:'/storeBoxGoods/goods/delete',//删除盲盒商品
    getManageMemberPower:'/storeUser/user/authority',//获取指定用户的权限
    addManageMemberWithPower:'/storeUser/user/add/power',//添加管理员
    updateManageInfo:'/storeUser/user/add/power',//修改管理员信息
    deleteManageById:'/storeUser/user/delete/power',//删除管理员
    deleteCardById:'/deleteCardById',//删除卡号
    searchBoxOrder:'/storeOrder/searchBoxOrder',//获取盲盒订单列表
    searchBoxOrderDetail:'/storeOrder/searchBoxOrderDetail',//获取盲盒购买订单 子订单详情
    queryConsigneeInfo:'/orderLogistics/queryConsigneeInfo',//获取发货信息接口
    queryDictItem:'/sysDictItem/list',//获取快递物流列表
    searchGoodsOrderList:'/order/searchGoodsOrderList',//实物订单列表
    searchCardsOrderList:'/order/searchCardsOrderList',//虚拟订单列表
    getBoxProSettingListBySaleNum:'/box/getBoxProSettingListBySaleNum',//盲盒更多概率设置
    testProfitBox:'/storeBlindBox/test/profit/box',//10000次开盲盒收益测试
    addProSettingDetail:'/box/addProSettingDetail',//添加盲盒第二套概率
    changeProSettingDetail:'/box/changeProSettingDetail',//修改盲盒第二套概率
    deleteProSettingDetail:'/box/deleteProSettingDetail',//删除盲盒额外参数
    changeShopStatus:'/storeGoods/update/status',//商品上下架
    getOperateData:'/storeOrder/operate/data',//获取运营数据
    changeProSettingUsed:'/storeBlindBox/box/used',//设置第二套概率是否启用
    sendCardList:'/sendCardList',//虚拟物品发货功能
    deleteShopList:'/storeGoods/delete/good',//删除商品
    getUserHistoryList:'/storeOrder/order/list',//获取用户订单数据
    getAllOrderDetail:'/storeOrder/all/order/detail',//获取所有待处理订单
    getBoxSaleByBoxId:'/box/getBoxSaleByBoxId',//获取盲盒的销量
    listConfig:'/baseConfig/listConfig',//配置列表
    addConfig:'/baseConfig/addConfig',//添加配置
}
